//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;
	static STRING_URL_BRINS_WEB: string = environment.URL_BRINS_WEB;
	static STRING_URL_BRINSDIGITAL_WEB: string = environment.URL_BRINS_DIGITAL_WEB;


	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForCustomer`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTPRODUCTFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForAccountingAndFinance`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTBRANCHPREMIUMREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectBranchPremiumRevenue`;
	static STRING_URL_DASHBOARD_SELECTREVENUEANDPROPORTIONALTARGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRevenueAndProportionalTarget`;
	static STRING_URL_DASHBOARD_SELECTMONEYNONMONEY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMoneyNonMoney`;
	static STRING_URL_DASHBOARD_SELECTMICROPOLICYAMOUNT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMicroPolicyAmount`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARRELATIONSHIPMANAGERREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRelationshipManagerRevenue`;
	static STRING_URL_DASHBOARD_SELECTGENERALPOSTREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectGeneralPostRevenue`;
	static STRING_URL_DASHBOARD_SELECTDAILYBRANCHPOSEMMOVEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDailyBranchPosemMovement`;
	static STRING_URL_DASHBOARD_SELECTPAIDPOSEM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPaidPosem`;
	static STRING_URL_DASHBOARD_SELECTPOLICYRENEWAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPolicyRenewal`;
	static STRING_URL_DASHBOARD_SELECTUNDERWRITINGACHIEVEMENTRESULT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectUnderwritingAchievementResult`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRevenue`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARREVENUEBYSEGMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRevenueBySegment`;
	static STRING_URL_DASHBOARD_SELECTPOSEMDETAILBYBRANCH: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPosemDetailByBranch`;
	static STRING_URL_DASHBOARD_SELECTPOSEMDETAILBYDAY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPosemDetailByDay`;

	static STRING_URL_DASHBOAD_SELECTDASHBOARDDAILYFOROPERATIONANDSUPPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDailyForOperationAndSupportByAttributes`;
	static STRING_URL_DASHBOAD_SELECTDASHBOARDDAILYFOROPERATIONANDSUPPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDailyForOperationAndSupportByToken`;

	static STRING_URL_DASHBOARD_SELECTNOTIFICATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectNotificationForCustomer`;

	//#endregions


	//#region OPERATION AND SUPPORT CONTROLLER

	static STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT: string = "/OperationAndSupport";

	static STRING_URL_OPERATIONANDSUPPORT_SELECTOPERATIONANDSUPPORTBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT}/selectOperationAndSupportByEmail`;
	static STRING_URL_OPERATIONANDSUPPORT_VERIFYOPERATIONANDSUPPORTBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT}/verifyOperationAndSupportByEmail`;
	static STRING_URL_OPERATIONANDSUPPORT_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT}/signOut`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocation`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForCustomer`;
	static STRING_URL_BRANCH_SELECTPROVINCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectProvince`;
	static STRING_URL_BRANCH_SELECTBRANCHBYPROVINCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByProvince`;
	static STRING_URL_BRANCH_SELECTBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByAttributes`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORSURVEY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocationForSurvey`;
	static STRING_URL_BRANCH_SELECTBRANCHFORSURVEY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForSurvey`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocationForWebsiteCustomer`;
	static STRING_URL_BRANCH_SELECTBRANCHFORBRINSEDITORIALWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForBRINSEditorialWorkingUnit`;

	//#endregion


	//#region BRANCH COVERAGE CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCHCOVERAGE: string = "/BranchCoverage";

	static STRING_URL_BRANCHCOVERAGE_SELECTBRANCHCOVERAGE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHCOVERAGE}/selectBranchCoverageForWebsiteCustomer`;

	//#endregion


	//#region BRI POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIPOLICY: string = "/BRIPolicy";

	static STRING_URL_BRIPOLICY_CHECKBRIPOLICYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIPOLICY}/checkBRIPolicyByAttributes`;

	//#endregion


	//#region BRI ENDORSEMENT POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY: string = "/BRIEndorsementPolicy";

	static STRING_URL_BRIENDORSEMENTPOLICY_INSERTENDORSEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/insertEndorsement`;
	static STRING_URL_BRIENDORSEMENTPOLICY_UPLOADENDORSEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/uploadEndorsement`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementByAttributes`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementByToken`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTAPPROVAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementApproval`;
	static STRING_URL_BRIENDORSEMENTPOLICY_APPROVEENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/approveEndorsementByToken`;
	static STRING_URL_BRIENDORSEMENTPOLICY_REJECTENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/rejectEndorsementByToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCT: string = "/Product";

	static STRING_URL_PRODUCT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByAttributes`;
	static STRING_URL_PRODUCT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByToken`;

	//#endregion


	//#region PRODUCT ASRI CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRIBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT ASRI SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH: string = "/ProductAsriSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRISYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRISYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH: string = "/ProductOtoSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTOSYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion

	//#region PRODUCT PERSONAL ACCIDENT SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH: string = "/ProductPersonalAccidentSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENTSYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region POLICY GENERAL CONTROLLER

	static STRING_PATH_CONTROLLER_POLICYGENERAL: string = "/PolicyGeneral";

	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributesForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByLicensePlate`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyholderAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByProfileID`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAcceptanceNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYTOKENFORPUBLIC: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByTokenForPublic`;

	//#endregion


	//#region CUSTOMER CONTROLLER

	static STRING_PATH_CONTROLLER_CUSTOMER: string = "/Customer";

	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByProfileID`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyNumber`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByLicensePlate`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyholderAttributes`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERTICKETBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerTicketByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERTICKETBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerTicketByEmail`;
	static STRING_URL_CUSTOMER_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/signOut`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByEmail`;
	static STRING_URL_CUSTOMER_UPDATECUSTOMERMOBILEPHONE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/updateCustomerMobilePhone`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERMOBILEPHONE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByMobilePhone`;
	static STRING_URL_CUSTOMER_UPDATECUSTOMERMOBILEPHONEBYSMS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/updateCustomerMobilePhoneBySMS`;
	static STRING_URL_CUSTOMER_UPDATECUSTOMERPERSONALDATA: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/updateCustomerPersonalData`;
	static STRING_URL_CUSTOMER_UPDATECUSTOMERPROFILE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/updateCustomerProfile`;
	static STRING_URL_CUSTOMER_UPDATECUSTOMERBYCONTACT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/updateCustomerByContact`;
	static STRING_URL_CUSTOMER_UPDATECUSTOMERBYCONTACTSMS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/updateCustomerByContactSMS`;
	static STRING_URL_CUSTOMER_PROFILECUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectProfileCustomerByAttributes`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYCONTACT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByContact`;
	static STRING_URL_CUSTOMER_REGISTERCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/registerCustomerByEmail`;
	static STRING_URL_CUSTOMER_SELECTLOCATIONBYPOSTALCODEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectLocationByPostalCodeForCustomer`;

	//#endregion


	//#region USER

	static STRING_PATH_CONTROLLER_USER: string = "/User";

	static STRING_URL_USER_UPLOADUSERWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_USER}/uploadUserWebsiteCustomer`;

	//#endregion


	//#region CLAIM CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM: string = "/Claim";

	static STRING_URL_CLAIM_SELECTCLAIMBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByProfileID`;

	static STRING_URL_CLAIM_SELECTCLAIMBYCLAIMNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByClaimNumber`;


	//#endregion


	//#region CLAIM CONTROLLER

	static STRING_PATH_CONTROLLER_PAYMENT: string = "/Payment";

	static STRING_URL_CLAIM_SELECTPAYMENTBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentByProfileID`;

	static STRING_URL_CLAIM_SELECTPAYMENTDETAILBYANO: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentDetailByANO`;


	//#endregion



	//#region CLAIM PROPOSAL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL: string = "/ClaimProposal";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicle`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORCUSTOMERBYREFERENCENUMBER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleForCustomerByReferenceNumber`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalForWebsiteCustomerPublic`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALSUPPORTINGGALLERY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalSupportingGallery`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovalByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTESFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributesForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForOperationAndSupport`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalForOperationAndSupport`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTESFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributesForOperationAndSupport`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByPolicyNumber`;
	static STRING_URL_CLAIM_PROPOSAL_SELECT_CLAIMPROPOSAL_BYTICKERNUMBER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByTicketNumber`;
	static STRING_URL_CLAIM_PROPOSAL_SELECT_CLAIMPROPOSAL_BYTATTRIBUTES_FOR_CLAIMTRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByAttributesForClaimTracking`;
	static STRING_URL_CLAIM_PROPOSAL_SELECT_CLAIMPROPOSAL_BYTOKEN_FOR_CLAIM_TRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByTokenForClaimTracking`;
	static STRING_URL_CLAIM_PROPOSAL_SELECT_CLAIMPROPOSAL_BYATTRIBUTES_FOR_CUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByAttributesForCustomer`;

	//#endregion


	//#region CLAIM PROPOSA TRACKINGL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL: string = "/ClaimProposalTracking";

	//#region SELECT

	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALTRACKINGBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/selectClaimProposalTrackingByClaimProposalToken`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECT_CLAIMPROPOSAL_TRACKING_BYCLAIMPROPOSALTOKEN_FORWEBSITE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/selectClaimProposalTrackingByClaimProposalTokenForWebsite`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_INSERTCLAIMPROPOSALTRACKINGFORPAYMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/insertClaimProposalTrackingForPayment`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_UPLOADPROPOSALTRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/uploadClaimProposalTracking`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_UPDATE_CLAIM_PROPOSAL_TRACKING_BYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/updateClaimProposalTrackingByToken`;

	//#endregion


	//#region CLAIM PROPOSAL SATISFACTION CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION: string = "/ClaimProposalSatisfaction";

	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_INSERTCLAIMPROPOSALSATISFACTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION}/insertClaimProposalSatisfactionForWebsite`;
	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECTCLAIMPROPOSALSATISFACTIONBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION}/selectClaimProposalSatisfactionByClaimProposalTokenForWebsite`;

	//#endregion


	//#region CLAIM PROPOSAL SATISFACTION CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION_QUESTION: string = "/ClaimProposalSatisfactionQuestion";

	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_QUESTION_SELECTC_CLAIM_PROPOSAL_SATISFACTION_QUESTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION_QUESTION}/selectClaimProposalSatisfactionQuestionForWebsite`;

	//#endregion


	//#region RELATIONSHIP DAMAGE TYPE

	static STRING_PATH_CONTROLLER_DAMAGETYPE: string = "/DamageType";
	static STRING_URL_DAMAGETYPE_SELECTDAMAGETYPE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DAMAGETYPE}/selectDamageType`;
	static STRING_URL_DAMAGETYPE_SELECTDAMAGETYPEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DAMAGETYPE}/selectDamageTypeForCustomer`;

	//#endregion


	//#region LICENSE PLATE PREFIX

	static STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX: string = "/LicensePlatePrefix";
	static STRING_URL_LICENSEPLATEPREFIX_SELECTLICENSEPLATEPREFIX: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX}/selectLicensePlatePrefix`;
	static STRING_URL_LICENSEPLATEPREFIX_SELECT_LICENSEPLATE_PREFIX_FOR_TRACKING_CLAIM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX}/selectLicensePlatePrefixForClaimTracking`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP: string = "/PartnershipWorkshop";

	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshop`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopPublic`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByAttributes`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYATTRIBUTESPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByAttributesPublic`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOPCITY: string = "/PartnershipWorkshopCity";

	static STRING_URL_PARTNERSHIPWORKSHOPCITY_SELECTPARTNERSHIPWORKSHOPCITY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOPCITY}/selectPartnershipWorkshopCity`;

	//#endregion


	//#region RELATIONSHIP MANAGER CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER: string = "/RelationshipManager";

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYATTRIBUTESFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByAttributesForOperationAndSupport`;
	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByToken`;
	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByBranch`;


	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;
	static STRING_URL_DIVISION_SELECTDIVISIONFORBRINSEDITORIALWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivisionForBRINSEditorialWorkingUnit`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETPUBLICBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketPublicByToken`;
	static STRING_URL_TICKET_SELECTTICKETTOKENPUBLICBYTICKETNUMBER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketTokenPublicByTicketNumber`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_INSERTTICKETFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForCustomer`;
	static STRING_URL_TICKET_INSERTTICKETFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForCustomerPublic`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;
	static STRING_URL_TICKET_INSERTTICKETRENEWALFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketRenewalForCustomerPublic`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForCustomer`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONPUBLICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationPublicByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion



	//#region VEHICLE BRAND CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrand`;

	//#endregion


	//#region VEHICLE MODEL CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELBYVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrand`;

	//#endregion


	//#region VEHICLE LOCATION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForCustomer`;

	//#endregion


	//#region RENEWAL

	static STRING_PATH_CONTROLLER_POLICYRENEWAL: string = "/PolicyRenewal";

	static STRING_URL_POLICYRENEWAL_SELECTRENEWALFIRSTAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalFirstAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALSECONDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalSecondAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALTHIRDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalThirdAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALFOURTHAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalFourthAging`;

	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFIRSTAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderFirstAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERSECONDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderSecondAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERTHIRDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderThirdAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFOURTHAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderFourthAging`;

	//#endregion


	//#endregion


	//#region TOC

	static STRING_PATH_CONTROLLER_TOC: string = "/TOC";

	static STRING_URL_TOC_SELECTTOC: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TOC}/selectTOC`;

	//#endregion


	//#region BRINS WEBSITE

	//#region BRINS UPDATE

	static STRING_PATH_CONTROLLER_BRINSUPDATE: string = "/BRINSUpdate";
	static STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSUPDATE}/selectBRINSUpdateByAttributes`;
	static STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSUPDATE}/selectBRINSUpdateByToken`;
	static STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSUPDATE}/selectBRINSUpdateByAttributesForCustomer`;
	//#endregion

	//#region AWARD

	static STRING_PATH_CONTROLLER_AWARD: string = "/Award";
	static STRING_URL_AWARD_SELECTAWARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AWARD}/selectAwardByAttributes`;
	static STRING_URL_AWARD_SELECTAWARDBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AWARD}/selectAwardByToken`;

	//#endregion

	//#region COMMUNITY

	static STRING_PATH_CONTROLLER_COMMUNITY: string = "/Community";
	static STRING_URL_COMMUNITY_SELECTCOMMUNITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_COMMUNITY}/selectCommunityByAttributes`;
	static STRING_URL_COMMUNITY_SELECTCOMMUNITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_COMMUNITY}/selectCommunityByToken`;

	//#endregion

	//#region DISCOUNT

	static STRING_PATH_CONTROLLER_DISCOUNT: string = "/Discount";
	static STRING_URL_DISCOUNT_SELECTDISCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountByAttributes`;
	static STRING_URL_DISCOUNT_SELECTDISCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountByToken`;
	static STRING_URL_DISCOUNT_SELECTDISCOUNTBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountByAttributesForCustomer`;

	//#endregion

	//#region FINANCIAL LITERACY

	static STRING_PATH_CONTROLLER_FINANCIALLITERACY: string = "/FinancialLiteracy";
	static STRING_URL_FINANCIALLITERACY_SELECTFINANCIALLITERACYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALLITERACY}/selectFinancialLiteracyByAttributes`;
	static STRING_URL_FINANCIALLITERACY_SELECTFINANCIALLITERACYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALLITERACY}/selectFinancialLiteracyByToken`;
	static STRING_URL_FINANCIALLITERACY_SELECTFINANCIALLITERACYBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALLITERACY}/selectFinancialLiteracyByAttributesForCustomer`;
	static STRING_URL_FINANCIALLITERACY_SELECTFINANCIALLITERACYBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALLITERACY}/selectFinancialLiteracyByTokenForCustomer`;

	//#endregion

	//#region NEWS FEED

	static STRING_PATH_CONTROLLER_NEWSFEED: string = "/NewsFeed";
	static STRING_URL_NEWSFEED_SELECTNEWSFEEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_NEWSFEED}/selectNewsFeedByAttributes`;
	static STRING_URL_NEWSFEED_SELECTNEWSFEEDBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_NEWSFEED}/selectNewsFeedByToken`;
	static STRING_URL_NEWSFEED_SELECTNEWSFEEDBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_NEWSFEED}/selectNewsFeedByAttributesForCustomer`;
	static STRING_URL_NEWSFEED_SELECTNEWSFEEDBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_NEWSFEED}/selectNewsFeedByTokenForCustomer`;

	//#endregion

	//#region TESTIMONY

	static STRING_PATH_CONTROLLER_TESTIMONY: string = "/Testimony";
	static STRING_URL_TESTIMONY_SELECTTESTIMONYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TESTIMONY}/selectTestimonyByAttributes`;
	static STRING_URL_TESTIMONY_SELECTTESTIMONYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TESTIMONY}/selectTestimonyByToken`;
	static STRING_URL_TESTIMONY_SELECTTESTIMONYBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TESTIMONY}/selectTestimonyByAttributesForCustomer`;
	static STRING_URL_TESTIMONY_SELECTTESTIMONYBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TESTIMONY}/selectTestimonyByTokenForCustomer`;

	//#endregion

	//#region FAQ TYPE

	static STRING_PATH_CONTROLLER_FAQTYPE: string = "/FrequentlyAskedQuestionsQuestionType";

	static STRING_URL_FAQTYPE_SELECTFAQQUESTIONTYPEBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FAQTYPE}/selectFrequentlyAskedQuestionsQuestionTypeByAttributesForCustomer`;
	static STRING_URL_FAQTYPE_SELECTFAQQUESTIONTYPEBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FAQTYPE}/selectFrequentlyAskedQuestionsQuestionTypeByTokenForCustomer`;

	//#endregion

	//#region FAQ SUBTYPE

	static STRING_PATH_CONTROLLER_FAQSUBTYPE: string = "/FrequentlyAskedQuestionsQuestionSubtype";

	static STRING_URL_FAQTYPE_SELECTFAQQUESTIONSUBTYPEBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FAQSUBTYPE}/selectFrequentlyAskedQuestionsQuestionSubtypeByTokenForCustomer`;

	//#endregion

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_EMAILSUBSCRIPTION: string = "/EmailSubscription";

	static STRING_URL_EMAILSUBSCRIPTION_INSERTEMAILSUBSCRIPTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMAILSUBSCRIPTION}/insertEmailSubscription`;
	static STRING_URL_EMAILSUBSCRIPTION_UPDATEEMAILSUBSCRIPTIONBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMAILSUBSCRIPTION}/updateEmailSubscriptionByEmail`;

	//#endregion


	//#region VERIFIER CONTROLLER

	static STRING_PATH_CONTROLLER_VERIFY_USER: string = "/User";
	static STRING_PATH_CONTROLLER_VERIFY_DECLARATION: string = "/Declaration";
	static STRING_PATH_CONTROLLER_VERIFY_SUBMISSION: string = "/Submission";

	static STRING_URL_USERVERIFY_SELECTUSERPUBLICBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VERIFY_USER}/selectUserPublicByToken`;
	static STRING_URL_DECLARATIONVERIFY_SELECTDECLARATIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VERIFY_DECLARATION}/selectDeclarationQRByToken`;
	static STRING_URL_SUBMISSIONVERIFY_SELECTSUBMISSIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VERIFY_SUBMISSION}/selectSubmissionQRByToken`;

	//#endregion


	//#region POSTAL

	static STRING_PATH_CONTROLLER_POSTAL: string = "/Postal";
	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCode`;
	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCodeForCustomer`;

	//#endregion


	//#region NOTIFICATION - ACTIVITY

	static STRING_PATH_CONTROLLER_ACTIVITY: string = "/UserActivityHistory";

	static STRING_URL_ACTIVITY_SELECTUSERACTIVITYHISTORY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACTIVITY}/selectUserActivityHistory`;
	static STRING_URL_ACTIVITY_UPDATEUSERACTIVITYHISTORYSEEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACTIVITY}/updateUserActivityHistorySeen`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;
	static STRING_URL_CURRENCY_SELECTCURRENCYFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region CUSTOMER SATISFACTION

	static STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION: string = "/CustomerSatisfaction";

	static STRING_URL_CUSTOMERSATISFACTION_INSERTCUSTOMERSATISFACTIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/insertCustomerSatisfactionForCustomer`;

	//#endregion


	//#region OUTLOOK ARTICLE

	static STRING_PATH_CONTROLLER_OUTLOOKARTICLE: string = "/OutlookArticle";

	static STRING_URL_OUTLOOKARTICLE_INSERTOUTLOOKARTICLE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOKARTICLE}/insertOutlookArticle`;

	//#endregion


	//#region OUTLOOK

	static STRING_PATH_CONTROLLER_OUTLOOK: string = "/Outlook";

	static STRING_URL_OUTLOOK_SELECTOUTLOOKFORPUBLIC: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOK}/selectOutlookForPublic`;
	static STRING_URL_OUTLOOK_SELECTOUTLOOKBYTOKENFORPUBLIC: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOK}/selectOutlookByTokenForPublic`;
	static STRING_URL_OUTLOOK_DOWNLOADOUTLOOKFILEFORPUBLIC: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OUTLOOK}/downloadOutlookFileForPublic`;

	//#endregion


	//#region Header

	static STRING_PATH_CONTROLLER_HEADER: string = "/Header";

	static STRING_URL_HEADER_SELECTHEADERWITHSUBHEADER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HEADER}/selectHeaderWithSubHeader`;
	static STRING_URL_HEADER_SELECTHEADERBYHEADERTYPE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HEADER}/selectHeaderByHeaderType`;

	//#endregion


	//#region Header

	static STRING_PATH_CONTROLLER_LANDINGHOMEPAGE: string = "/LandingHomepage";

	static STRING_URL_LANDINGHOMEPAGE_SELECTLANDINGHOMEPAGE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LANDINGHOMEPAGE}/selectLandingHomepage`;

	//#endregion


	//#region Financial Report

	static STRING_PATH_CONTROLLER_FINANCIALREPORT: string = "/FinancialReport";

	static STRING_URL_FINANCIALREPORT_SELECTFINANCIALREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/selectFinancialReportByAttributes`;
	static STRING_URL_FINANCIALREPORT_SELECTFINANCIALREPORTFORSEARCH: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALREPORT}/selectFinancialReportForSearch`;

	//#endregion


	//#region Organization Structure

	static STRING_PATH_CONTROLLER_ORGANIZATIONSTRUCTURE: string = "/OrganizationStructure";

	static STRING_URL_ORGANIZATIONSTRUCTURE_SELECTORGANIZATIONSTRUCTUREBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ORGANIZATIONSTRUCTURE}/selectOrganizationStructureByAttributes`;
	static STRING_URL_ORGANIZATIONSTRUCTURE_SELECTORGANIZATIONSTRUCTUREBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ORGANIZATIONSTRUCTURE}/selectOrganizationStructureByToken`;

	//#endregion


	//#region Organization Structure

	static STRING_PATH_CONTROLLER_RIPLAYPRODUCTDOCUMENT: string = "/RiplayProductDocument";

	static STRING_URL_RIPLAYPRODUCTDOCUMENT_SELECTRIPLAYPRODUCTDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RIPLAYPRODUCTDOCUMENT}/selectRiplayProductDocumentByAttributes`;

	//#endregion


	//#endregion
}

//#endregion
