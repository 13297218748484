<div id="divBodyAbout" class="DivBodyContainer">
	<div class="DivContentMain">
		<div class="DivContentAbout">
			<div class="DivHeaderContent">
				<h1>{{_modelHeader.TitleFirst}}</h1><h1> {{_modelHeader.TitleSecond}}</h1>
			</div>
			<div class="DivContentDescription">
				<p>{{_modelHeader.Description}}</p>
			</div>
			<div class="DivContentSearch">
				<img src="../../assets/icons/sakina/icon_search.svg"  alt="Search">
				<div class="DivContentSearchDetailFilter">
					<input type="text" [(ngModel)]="_stringInputValue" (keyup)="searchByValue()" placeholder="Cari Laporan Keuangan" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)">
					<div class="DivContentSearchResult" *ngIf="this._modelFinancialReportSearch.length !== 0">
						<ul>
							<ng-container *ngFor="let modelFinancialReportSearch of this._modelFinancialReportSearch">
								<ng-container *ngFor="let modelFinancialReportSearchGallery of modelFinancialReportSearch.listModelFinancialReportGallery">
									<li (click)="downloadFile(modelFinancialReportSearchGallery.URL!)">{{ modelFinancialReportSearch.Title }}</li>
								</ng-container>
							</ng-container>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="DivContentImage">
			<figure>
				<img *ngIf="_modelHeader.listModelHeaderGallery !== undefined && _modelHeader.listModelHeaderGallery.length > 0" [src]="getImageFromBroadcastURL(_modelHeader.listModelHeaderGallery[0].URL)">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_3.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_4.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_1.svg">
			</figure>
		</div>
	</div>
</div>

<div id="divBodyTabAbout" class="DivBodyContainer">
	<h1>Laporan Keuangan Tahunan</h1>
	<div class="DivContentDownload">
		<div class="DivContentDownloadMedium" *ngFor="let modelFinancialReport of _arrayModelFinancialReportTableYearly">
			<img src="../../assets/icons/sakina/icon_file_pdf.svg" alt="pdf">
			<div class="DivContentDownloadDescription">
				<label for="">{{ modelFinancialReport.Title }}</label>
				<label>{{ modelFinancialReport.Year }}</label>
				<ng-container *ngFor="let modelFinancialReport of modelFinancialReport.listModelFinancialReportGallery">
					<a (click)="downloadFile(modelFinancialReport.URL!)"><img src="../../assets/icons/sakina/icon_download_brins.svg">Unduh</a>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="DivTableContainer">
		<app-tablecontrolincludemini [_modelTableForm]="_modelTableYearly" (_eventEmitterSelect)="setEventEmitterSelectYearly($event);"></app-tablecontrolincludemini>
	</div>
</div>

<div id="divBodyTabAbout" class="DivBodyContainer">
	<h1>Laporan Keuangan Bulanan</h1>
    <div class="DivContentMain">
        <div class="DivContentTabAbout">
            <button (click)="goToQuarterlyReports(_enumFinancialReportInsuranceType.Conventional)" [ngClass]="_numberQuarterlyReportsValueTypeActive ===  _enumFinancialReportInsuranceType.Conventional ? 'ButtonActive' : 'ButtonInActive'"> Konvensional </button>
            <button (click)="goToQuarterlyReports(_enumFinancialReportInsuranceType.Sharia)" [ngClass]="_numberQuarterlyReportsValueTypeActive ===  _enumFinancialReportInsuranceType.Sharia ? 'ButtonActive' : 'ButtonInActive'"> Syariah </button>
		</div>
    </div>

	<ng-container *ngIf="_numberQuarterlyReportsValueTypeActive === _enumFinancialReportInsuranceType.Conventional">
		<div class="DivContentDownload">
			<div class="DivContentDownloadMedium" *ngFor="let modelFinancialReport of _arrayModelFinancialReportTableMonthly">
				<img src="../../assets/icons/sakina/icon_file_pdf.svg" alt="pdf">
				<div class="DivContentDownloadDescription">
					<label for="">{{ modelFinancialReport.Title }}</label>
					<ng-container *ngFor="let modelFinancialReport of modelFinancialReport.listModelFinancialReportGallery">
						<a (click)="downloadFile(modelFinancialReport.URL!)"><img src="../../assets/icons/sakina/icon_download_brins.svg">Unduh</a>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="DivTableContainer">
			<app-tablecontrolincludemini [_modelTableForm]="_modelTableMonthly" (_eventEmitterSelect)="setEventEmitterSelectMonthly($event, _enumFinancialReportInsuranceType.Conventional);"></app-tablecontrolincludemini>
		</div>
	</ng-container>

	<ng-container *ngIf="_numberQuarterlyReportsValueTypeActive === _enumFinancialReportInsuranceType.Sharia">
		<div class="DivContentDownload">
			<div class="DivContentDownloadMedium" *ngFor="let modelFinancialReport of _arrayModelFinancialReportTableMonthly">
				<img src="../../assets/icons/sakina/icon_file_pdf.svg" alt="pdf">
				<div class="DivContentDownloadDescription">
					<label for="">{{ modelFinancialReport.Title }}</label>
					<ng-container *ngFor="let modelFinancialReport of modelFinancialReport.listModelFinancialReportGallery">
						<a (click)="downloadFile(modelFinancialReport.URL!)"><img src="../../assets/icons/sakina/icon_download_brins.svg">Unduh</a>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="DivTableContainer">
			<app-tablecontrolincludemini [_modelTableForm]="_modelTableMonthly" (_eventEmitterSelect)="setEventEmitterSelectMonthly($event, _enumFinancialReportInsuranceType.Sharia);"></app-tablecontrolincludemini>
		</div>
	</ng-container>
</div>

<app-footer></app-footer>